<template>
  <v-card>
    <v-card-title class="primary white--text">
      <span class="title">Add/Update</span>
    </v-card-title>
    <v-card-text class="pb-1 pt-4">
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
        @submit.prevent="save"
        @keydown.native="form.errors.clear($event.target.name)"
        @keyup.enter="save"
      >
        <v-text-field
          type="search"
          autocomplete="off"
          label="Author Name*"
          required
          class="pa-0"
          outlined
          dense
          v-model="form.name"
          name="name"
          :error-messages="form.errors.get('name')"
          :rules="[(v) => !!v || 'Name is required']"
        />
        <v-text-field
          autocomplete="off"
          label="Email"
          required
          class="pa-0"
          outlined
          dense
          v-model="form.email"
          :rules="emailRule"
          :error-messages="form.errors.get('email')"
        />
        <v-select
          autocomplete="off"
          :items="nationality"
          label="Nationality"
          outlined
          dense
          v-model="form.nationality"
          class="pa-0"
        ></v-select>
        <v-text-field
          autocomplete="off"
          label="Address"
          required
          class="pa-0"
          outlined
          dense
          v-model="form.address"
        />
        <v-text-field
          autocomplete="off"
          label="Phone Number"
          required
          class="pa-0"
          outlined
          dense
          type="number"
          v-model="form.phone_number"
        />
        <v-text-field
          autocomplete="off"
          label="Mobile Number"
          required
          class="pa-0"
          outlined
          dense
          type="number"
          v-model="form.mobile_number"
        />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(form.dialog = false), form.reset(), (save_flag = true)"
            >Close</v-btn
          >
          <v-btn color="success" text @click="validate" v-if="save_flag"
            >Save</v-btn
          >
          <v-btn color="success" text @click="validate" v-else>Update</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import Form from "@/library/Form";
import nationalityData from "@/assets/json/nationality.json";
import Mixins from "@/library/Mixins";
export default {
  mixins: [Mixins],
  data: () => ({
    save_flag: true,
    valid: true,
    lazy: false,
    nationalityData,
    nationality: [],
    form: new Form(
      {
        dialog: false,
        name: "",
        nationality: "",
        address: "",
        phone_number: "",
        mobile_number: "",
        email: "",
        age: "",
      },
      "library/api/author"
    ),
    emailRule: [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"],

    headers: [
      { text: "#", align: "left", value: "id", sortable: false },
      { text: "Name", align: "left", value: "name", sortable: false },
      {
        text: "Contact",
        align: "left",
        value: "mobile_number",
        sortable: false,
      },
      {
        text: "Nationality",
        align: "left",
        value: "nationality",
        sortable: false,
      },
      { text: "Address", align: "left", value: "address", sortable: false },
      { text: "Email", align: "left", value: "email", sortable: false },
      { text: "Action", align: "right", sortable: false },
    ],
  }),

  mounted() {
    this.getNationality();
  },
  methods: {
    getNationality() {
      this.nationalityData.forEach((obj) => {
        this.nationality.push(obj.nationality);
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.form.store();
      }
    },
  },
};
</script>